import { default as NextImage, ImageProps } from 'next/image';

export interface IStrapiImageAttributes {
	__typename?: 'UploadFile';
	size?: number;
	ext?: string;
	url: string;
	alternativeText?: string;
	formats?: Record<string, any>;
	blurhash?: string;
	mime?: string;
}

export interface IImageProps extends Omit<ImageProps, 'src' | 'alt' | 'placeholder'> {
	showPlaceholder?: boolean;
	imageSize?: 'thumbnail' | 'small' | 'medium' | 'large';
	fallbackAlt?: string;
	attributes?: IStrapiImageAttributes;
}

export const Image = ({
	imageSize,
	attributes,
	fallbackAlt,
	showPlaceholder = true,
	...rest
}: IImageProps): React.JSX.Element => {
	const alt = attributes?.alternativeText || fallbackAlt || '';
	const imagePlaceholder = showPlaceholder ? 'https://d3irzjarhqj80y.cloudfront.net/Placeholder.png' : '';
	const sizedImageUrl =
		!!imageSize && attributes?.formats && attributes.formats[imageSize]
			? attributes.formats[imageSize].url
			: attributes?.url;

	return (
		<NextImage
			{...rest}
			src={sizedImageUrl || imagePlaceholder}
			alt={alt}
			placeholder={attributes?.blurhash ? 'blur' : undefined}
			blurDataURL={attributes?.blurhash}
		/>
	);
};

export default Image;
