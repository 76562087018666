import {
	DefaultModuleOptionsFieldsFragment,
	DefaultModuleOptionsExtendedFieldsFragment,
	Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor,
	Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor,
	Enum_Componentinternaldefaultmoduleoptions_Padding
} from 'api/graphql-types';
import clsx from 'clsx';
import React from 'react';
import styles from './base-module.module.scss';

type IModuleOptions =
	| DefaultModuleOptionsFieldsFragment
	| DefaultModuleOptionsExtendedFieldsFragment
	| null
	| undefined;

interface IBaseModuleProps {
	defaultModuleOptions?: IModuleOptions;
	children: React.ReactNode;
}

export const getBackgroundStyles = (
	background?:
		| Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor
		| Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor
): string => {
	switch (background) {
		case 'white':
			return styles.backgroundWhite;
		case 'grey':
			return styles.backgroundGrey;
		case 'darkblue':
			return styles.backgroundPurple;
		default:
			return styles.backgroundWhite;
	}
};

const getPaddingStyles = (padding?: Enum_Componentinternaldefaultmoduleoptions_Padding): string => {
	switch (padding) {
		case 'none':
			return styles.paddingNone;
		case 'small':
			return styles.paddingSmall;
		case 'medium':
			return styles.paddingMedium;
		case 'large':
			return styles.paddingLarge;
		default:
			return styles.paddingNone;
	}
};

export const getStyles = (options: IModuleOptions): { padding: string; background: string } => {
	const padding =
		(options as DefaultModuleOptionsFieldsFragment)?.padding ||
		(options as DefaultModuleOptionsExtendedFieldsFragment)?.extendedPadding;
	const background =
		(options as DefaultModuleOptionsFieldsFragment)?.backgroundColor ||
		(options as DefaultModuleOptionsExtendedFieldsFragment)?.extendedBackgroundColor;

	return {
		padding: getPaddingStyles(padding),
		background: getBackgroundStyles(background)
	};
};

export const BaseModule = (props: IBaseModuleProps): React.JSX.Element => {
	const childrenProps: any = props.children;
	const { padding, background } = getStyles(props.defaultModuleOptions);
	const childModule = childrenProps.props?.module;
	const isHomepageHeader = childModule?.__typename === 'ComponentModulesHomepageHeader';
	const isPhotoAlbumSmall = childModule?.__typename === 'ComponentModulesPhotoAlbum' && childModule?.horizontal;
	const overflow = isPhotoAlbumSmall && styles.overflow;

	return (
		<section className={clsx(styles.section, padding, background, overflow)}>
			<div className={clsx('container', isHomepageHeader && 'isHomepageHeader')}>{props.children}</div>
		</section>
	);
};
