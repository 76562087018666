import { createElement } from 'react';
import { IconContext, IconType } from 'react-icons/lib';
import * as MdIcons from 'react-icons/md';

import styles from './icon.module.scss';

export interface IIconProps extends IconContext {
	reactIcon?: string;
	customIcon?: React.JSX.Element;
	forceColor?: string;
}

export const Icon = ({ customIcon, reactIcon, forceColor, ...rest }: IIconProps): React.JSX.Element => {
	if (reactIcon) {
		return <ReactIcon reactIcon={reactIcon} {...rest} />;
	}

	return (
		<div
			className={styles.customIcon}
			style={{ height: rest.size || '16px', width: rest.size || '16px', color: forceColor ?? undefined }}
		>
			{customIcon}
		</div>
	);
};

export interface IReactIconProps extends IIconProps {
	reactIcon: string;
}

const ReactIcon = ({ reactIcon, ...rest }: IReactIconProps): React.JSX.Element => {
	const allMdIcons = MdIcons as any;
	const mdIcon: IconType = allMdIcons[reactIcon];
	return (
		<IconContext.Provider value={{ ...rest }}>
			<div className={styles.icon}>{createElement(mdIcon, { ...rest })}</div>
		</IconContext.Provider>
	);
};
