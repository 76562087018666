import React from 'react';
import clsx from 'clsx';
import { NextLink, INextLinkProps } from 'components/next-link';
import styles from './link.module.scss';

export type ILinkVariants = 'tertiary-light' | 'tertiary-dark';

export interface ILinkProps extends INextLinkProps {
	variant: ILinkVariants;
}

const Link = ({ variant, children, ...rest }: ILinkProps): React.JSX.Element => (
	<span className={clsx(styles[variant], styles.link)}>
		<NextLink {...rest}>{children}</NextLink>
	</span>
);

export { Link };
