import NextHead from 'next/head';
import { pickBy, identity } from 'lodash';
import { SeoFieldsFragment, SeoSingleFieldsFragment } from 'api/graphql-types';

export interface IHeadProps {
	seoParent?: SeoSingleFieldsFragment;
	seoModule?: SeoFieldsFragment;
}

const Head = ({ seoParent, seoModule }: IHeadProps): React.JSX.Element => {
	// Set seo parent as faalback data, overwrite data with set module data
	const { noFollow, noIndex, title, description, ogUrl, ogType, ogTitle, ogDescription, ogImage } = {
		...seoParent,
		...pickBy(seoModule, identity),
		ogTitle: seoModule?.ogTitle || seoModule?.title || seoParent?.ogTitle || seoParent?.title,
		ogDescription:
			seoModule?.ogDescription || seoModule?.description || seoParent?.ogDescription || seoParent?.description
	};

	const imageUrl = ogImage?.data?.attributes?.url;

	return (
		<NextHead>
			{/* Robots */}
			{noFollow && <meta name="robots" content="nofollow" key="nofollow" />}
			{noIndex && <meta name="robots" content="noindex" key="noindex" />}

			{/* Primary Meta Tags */}
			{title && <title key="title">{title}</title>}
			{title && <meta name="title" content={title} />}
			{description && <meta name="description" content={description} />}

			{/* Open Graph / Facebook */}
			<meta property="og:site_name" content={seoParent?.title || seoParent?.ogTitle} />
			{ogUrl && <meta property="og:url" content={ogUrl} />}
			{ogType && <meta property="og:type" content={ogType} />}
			{ogTitle && <meta property="og:title" content={ogTitle} />}
			{ogDescription && <meta property="og:description" content={ogDescription} />}
			{imageUrl && <meta property="og:image" content={imageUrl} />}

			{/* Twitter */}
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:site" content="@provgelderland" />
			<meta name="twitter:creator" content="@provgelderland" />
			{ogTitle && <meta name="twitter:title" content={ogTitle} />}
			{ogDescription && <meta name="twitter:description" content={ogDescription} />}
			{imageUrl && <meta name="twitter:image" content={imageUrl} />}
			{ogUrl && <meta property="twitter:url" content={ogUrl} />}
		</NextHead>
	);
};

export { Head };
