export const checkExternalUrl = (url?: string): boolean => {
	if (!url) return false;

	if (url.indexOf('action.spike.email') > 0) return true;

	return !!url.match(externalUrlRegex);
};

// Finds host url with protocol (used for Archiefweb)
export const urlHostRegex = /https?:\/\/?(www.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,24}(:\d{2,4})?/g;

// allows following urls: mailto:, tel:, https://, http://, /, #
export const urlRegex =
	/^$|[\/#][-a-zA-Z0-9@:%_\+.~#?&\/\/=]*|((https?:\/\/|mailto:)|www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)|(tel:)[+?0-9]{4,15}/g;

// allows allows following urls: mailto:, tel:, https://, http://
export const externalUrlRegex =
	/^$|((https?:\/\/|mailto:)|www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)|(tel:)[+?0-9]{4,15}/g;
