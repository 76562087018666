import { externalUrlRegex, urlHostRegex } from './check-external-url';
import { VALID_HOSTNAMES, VALID_PROTOCOLS } from './rich-text-renderer/constants';

export const isValidExternalURL = (href: string): boolean => {
	try {
		const matchedURL = href.match(externalUrlRegex);
		const url = matchedURL ? !!createValidExternalURL(href) : false;

		return url;
	} catch {
		return false;
	}
};

export const createValidExternalURL = (href: string): URL => {
	// Adds https protocol when non is given
	const startstWithProtocol = VALID_PROTOCOLS.some((protocol) => href.startsWith(protocol));
	const url = startstWithProtocol ? new URL(href) : new URL(`https://${href}`);

	return url;
};

export const createInternalURL = (href: string): string => {
	const host = location.href && location.href.match(urlHostRegex);
	const prefix =
		location.href.includes('archiefweb.eu') && host && host[1] ? (href.startsWith('/') ? host[1] : `${host[1]}/`) : '/';

	if (!isValidExternalURL(href)) {
		return (href.startsWith('/') && prefix === '/') || href.startsWith('#') ? href : `${prefix}${href}`;
	}

	return '#';
};

export const createValidURL = (href: string): string => {
	if (isValidExternalURL(href)) {
		return createValidExternalURL(href).href;
	} else {
		return createInternalURL(href);
	}
};

export const URLContainsAllowedDomain = (url: URL): boolean => {
	return VALID_HOSTNAMES.includes(url.hostname);
};
