import React from 'react';
import { useRouter } from 'next/router';
import styles from './docreader.module.scss';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import DocReaderIcon from 'public/assets/icons/docreader.svg';

const DOCREADER_URL = 'https://docreader-eu.readspeaker.com/docreader/';
const DOCREADER_CID = '4480';
const DOCUMENT_EXTENSIONS = [
	'.odp',
	'.ods',
	'.odt',
	'.epub',
	'.xls',
	'.xlsx',
	'.pps',
	'.ppt',
	'.pptx',
	'.doc',
	'docx',
	'.pdf',
	'.rtf'
];

interface IProps {
	className?: string;
	url: string;
}

const parseUrl = (url: string): URL | null => {
	try {
		return new URL(url);
	} catch {
		return null;
	}
};

const mapDocReaderUrl = (url: URL, locale: string): string => {
	const docReaderUrl = new URL(DOCREADER_URL);
	docReaderUrl.searchParams.append('cid', DOCREADER_CID);
	docReaderUrl.searchParams.append('lang', locale);
	docReaderUrl.searchParams.append('url', url.toString());
	return docReaderUrl.toString();
};

const isDocumentUrl = (url: URL): boolean => {
	return DOCUMENT_EXTENSIONS.some((extension) => url.pathname.endsWith(extension));
};

export const DocReader = (props: IProps): React.JSX.Element | null => {
	const intl = useIntl();
	const router = useRouter();
	const locale = router.locale || 'nl';
	const urlObject = parseUrl(props.url);

	if (!urlObject) {
		return null;
	}

	const docReaderUrl = mapDocReaderUrl(urlObject, locale);
	const isDocument = isDocumentUrl(urlObject);

	if (!isDocument) {
		return null;
	}

	return (
		<a
			className={clsx(props.className, styles.link)}
			title={intl.formatMessage({ id: 'components.docReader.title' })}
			href={docReaderUrl.toString()}
			rel="noopener noreferrer"
			target="_blank"
		>
			<DocReaderIcon className={styles.icon} alt={intl.formatMessage({ id: 'components.docReader.ariaLabel' })} />
		</a>
	);
};
