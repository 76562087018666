import React from 'react';
import clsx from 'clsx';

import styles from './typography.module.scss';

export type ITypographyHeadingVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type ITypographyTagVariants = 'p' | 'small' | 'screenreader' | 'strong' | 'intro' | 'card';
export type ITypographyVariants = ITypographyHeadingVariants | ITypographyTagVariants;

export type AlignOptions = 'left' | 'center' | 'right' | 'justify';

export interface ITypographyProps extends React.HTMLAttributes<HTMLDivElement> {
	id?: string;
	tag?: keyof React.JSX.IntrinsicElements;
	variant?: ITypographyVariants;
	align?: AlignOptions;
	children?: React.ReactNode | React.ReactNode[];
}

export const Typography = ({ variant = 'p', tag, align, children, ...rest }: ITypographyProps): React.JSX.Element => {
	const mapVariantToTag = (): string => {
		switch (variant) {
			case 'screenreader':
				return 'span';
			case 'card':
				return 'div';
			default:
				return variant;
		}
	};

	const CustomTag = (tag || mapVariantToTag()) as any;
	const customStyling = styles[variant] ?? undefined;

	let title = '';
	if (typeof children === 'string' || children instanceof String) {
		title = children.toString();
	}

	return (
		<CustomTag id={title.toLowerCase()} className={clsx(customStyling, align && styles[align])} {...rest}>
			{children}
		</CustomTag>
	);
};

export default Typography;
