import React, { useEffect, useState } from 'react';
import Link, { LinkProps } from 'next/link';
import { UrlObject } from 'url';

import { useAppSelector } from 'redux/store';
import { createValidURL } from 'utils/url';
import { DocReader } from 'components/docreader/docreader';

export interface INextLinkProps extends Omit<LinkProps, 'href'> {
	id?: string;
	href?: string | UrlObject;
	children?: any;
}

export const NextLink = ({ href = '#', children, ...rest }: INextLinkProps): React.JSX.Element => {
	const [url, setUrl] = useState<string | UrlObject>(href);
	const { publicationState } = useAppSelector((state) => state.settings);

	// Don't prefetch in preview mode to prevent performance issues
	if (!rest.prefetch && publicationState === 'PREVIEW') {
		rest.prefetch = false;
	}

	useEffect(() => {
		if (href && typeof href === 'string') {
			setUrl(createValidURL(href));
		} else if (href && typeof href === 'object') {
			setUrl({ ...href, pathname: createValidURL((href as UrlObject).pathname || '#') });
		}
	}, [href]);

	return (
		<>
			<Link {...rest} href={url || '/'}>
				{children}
			</Link>
			<DocReader url={url?.toString()} />
		</>
	);
};

export default NextLink;
