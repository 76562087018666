export const VALID_HOSTNAMES = [
	'strapi.tst.gelderland.nl',
	'strapi.tst.gelderland.nl',
	'strapi.poc.gelderland.nl',
	'gelderland.nl',
	'localhost'
];
export const VALID_PROTOCOLS = ['http:', 'https:', 'tel:', 'mailto:'];
export const VALID_SCHEMES = ['http', 'https', 'tel', 'mailto'];
export const ALLOWED_IMAGE_ATTRIBUTES = ['src', 'alt', 'title', 'style'];
export const ALLOWED_LINK_ATTRIBUTES = ['href', 'alt'];
export const ALLOWED_TD_ATTRIBUTES = ['colspan'];
export const ALLOWED_HTML_TAGS = [
	'h2',
	'h3',
	'h4',
	'h5',
	'p',
	'a',
	'b',
	'em',
	'i',
	'strong',
	'img',
	'hr',
	'br',
	'q',
	'blockquote',
	'ul',
	'ol',
	'li'
];
export const TABLE_TAGS = [
	'table',
	'tr',
	'th',
	'td',
	'thead',
	'tbody',
	'figure',
	'caption',
	'p',
	'a',
	'b',
	'em',
	'strong',
	'ul',
	'li',
	'ol'
];
export const DOCREADER_EXTENSIONS = [
	'odp',
	'ods',
	'odt',
	'epub',
	'xls',
	'xlsx',
	'pps',
	'ppt',
	'pptx',
	'doc',
	'docx',
	'pdf',
	'rtf'
];
